import {observer} from "mobx-react";
import {FieldInput} from "../../../../../ui";
import {registrationStore} from "../../../../../../store";
import {FieldLicensePlate} from "../../../../../ui/FieldLicensePlate";
import {registrationValidationStore} from "../../../../../../store/RegistrationValidationStore";
import {RegistrationVehicleTypeField} from "../../../fields/RegistrationVehicleTypeField";
import {
    RegistrationCertificationOfRegistrationNumberField
} from "../../../fields/RegistrationCertificationOfRegistrationNumberField";
import {RegistrationVinField} from "../../../fields/RegistrationVinField";
import {RegistrationLicensePlateField} from "../../../fields/RegistrationLicensePlateField";

const VehiclePanelTz = observer(function VehiclePanelNz() {
    const reservationNumberHelp = registrationValidationStore.getFor('reservationNumber')

    return <>
        <nav className="panel">
            <p className="panel-heading">Fahrzeug</p>
            <div className="panel-block">
                <div>
                    <div className={"columns"}>
                        <div className={"column is-half"}>
                            <RegistrationVinField/>
                        </div>
                        <div className={"column is-half"}>
                            <RegistrationVehicleTypeField/>
                        </div>
                    </div>
                    {registrationStore.finishedCertificateOfRegistrationSerial && <div className={"columns"}>
                        <div className={"column is-half"}>
                            <FieldInput
                                disabled={true}
                                label={"Neue ZBI Nummer"}
                                value={registrationStore.finishedCertificateOfRegistrationSerial}
                                onChange={() => {}}
                            />
                        </div>
                    </div>}
                    <div className={"columns"}>
                        {registrationStore.finishedCertificateOfRegistrationNumber && <div className={"column is-half"}>
                            <FieldInput
                                disabled={true}
                                label={"Neue ZBII Nummer"}
                                required={true}
                                value={registrationStore.finishedCertificateOfRegistrationNumber}
                                onChange={() => {}}
                            />
                        </div>}
                        <div className={"column is-half"}>
                            <RegistrationCertificationOfRegistrationNumberField required={true}/>
                        </div>
                    </div>
                    <div className={"columns"}>
                        {registrationStore.finishedLicensePlate && <div className={"column is-half"}>
                            <div style={{display: "flex"}}>
                                <FieldLicensePlate
                                    disabled={true}
                                    value={registrationStore.finishedLicensePlate}
                                    onChange={() => {}}
                                />
                            </div>
                        </div>}
                        <div className={"column is-half"}>
                            <div style={{display: "flex"}}>
                                <RegistrationLicensePlateField
                                    licensePlateField={'licensePlate'}
                                    onChange={v => registrationStore.licensePlate = v}/>
                            </div>
                        </div>
                    </div>
                    <div className={"columns"}>
                        <div className={"column is-half"}>
                            <FieldInput
                                maxLength={8}
                                disabled={registrationStore.isReadonly}
                                label={"Reservierungsnummer"}
                                value={registrationStore.reservationNumber}
                                onChange={e => {
                                    registrationStore.reservationNumber = e
                                }}
                                onBlur={() => registrationStore.validate()}
                                help={reservationNumberHelp}
                                invalid={Boolean(reservationNumberHelp)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </>
})

export {
    VehiclePanelTz
}
