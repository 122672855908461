import {observer} from "mobx-react";
import {CitySelect, FieldInput, FieldSelect} from "../../../../ui";
import React from "react";
import {
    CourtEnum,
    courtName,
    courtOptions,
    registrationStore,
    searchCourt
} from "../../../../../store";
import {economicSectors, HolderDto, HolderDtoEconomicSectorEnum, HolderDtoRegisterTypeEnum} from "../../../../../api";
import {FieldAutoComplete} from "../../../../ui/FieldAutoComplete";
import {registrationValidationStore} from "../../../../../store/RegistrationValidationStore";

const economicSectorLookupData = Object.keys(HolderDtoEconomicSectorEnum).map(k => ({
    value: k as HolderDtoEconomicSectorEnum,
    label: economicSectors(k as HolderDtoEconomicSectorEnum)
}))

const allEconomicSectors = Object.keys(HolderDtoEconomicSectorEnum) as HolderDtoEconomicSectorEnum[]

function searchEconomicSector(v: string): HolderDtoEconomicSectorEnum[] {
    return economicSectorLookupData.filter(e => {
        return e.value === v || e.label.toLowerCase().split(v.toLowerCase()).length >= 2
    }).map(e => e.value)
}

const registerTypeOptions: {
    label: string,
    value: string
}[] = Object.keys(HolderDtoRegisterTypeEnum)
    .filter(e => e === HolderDtoRegisterTypeEnum.HRA || e === HolderDtoRegisterTypeEnum.HRB)
    .map(e => ({label: e, value: e}))


const HolderLegalForm = observer(function HolderLegalForm() {
    const holder = registrationStore.holder
    const disabled = registrationStore.isReadonly

    const economicSectorHelp = registrationValidationStore.getFor("holder.economicSector")
    const nameHelp = registrationValidationStore.getFor("holder.name")
    const streetHelp = registrationValidationStore.getFor("holder.street")
    const houseNumberHelp = registrationValidationStore.getFor("holder.houseNumber")
    const emailAddressHelp = registrationValidationStore.getFor("holder.emailAddress")
    const cityHelp = registrationValidationStore.getFor("holder.city")
        || registrationValidationStore.getFor("holder.postCode")
    const courtIdHelp = registrationValidationStore.getFor("holder.courtId")
    const registerTypeHelp = registrationValidationStore.getFor("holder.registerType")
    const registerNumberHelp = registrationValidationStore.getFor("holder.registerNumber")


    function update(key: keyof HolderDto, val: any) {
        // @ts-ignore
        holder[key] = val
        registrationStore.holder = holder
    }

    return <>
        <div className={"columns"}>
            <div className={"column is-half"}>
                <FieldInput disabled={disabled} value={holder.name} help={nameHelp} invalid={Boolean(nameHelp)}
                            onChange={v => update('name', v)} label={"Name"} required={true}
                            onBlur={() => registrationStore.validate()}/>
            </div>
            <div className={"column is-half"}>
                <FieldAutoComplete
                    disabled={disabled}
                    label={"Wirtschaftszweig"}
                    required={true}
                    value={holder.economicSector}
                    options={allEconomicSectors}
                    invalid={Boolean(economicSectorHelp)}
                    help={economicSectorHelp}
                    onChange={v => {
                        holder.economicSector = v
                        registrationStore.holder = holder
                    }}
                    onBlur={() => registrationStore.validate()}
                    itemRenderer={e => economicSectors(e)}
                    search={async v => {
                        return searchEconomicSector(v)
                    }}
                />
            </div>
        </div>
        <div className={"columns"}>
            <div className={"column is-half"}>
                <FieldInput disabled={disabled} invalid={Boolean(streetHelp)} help={streetHelp} value={holder.street}
                            onChange={v => update('street', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"Straße"} required={true}/>
            </div>
            <div className={"column is-one-quarter"}>
                <FieldInput disabled={disabled} invalid={Boolean(houseNumberHelp)} help={houseNumberHelp}
                            value={holder.houseNumber}
                            onChange={v => update('houseNumber', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"Hausnummer"} required={true}/>
            </div>
            <div className={"column is-one-quarter"}>
                <FieldInput disabled={disabled} value={holder.addressAddition}
                            onChange={v => update('addressAddition', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"Hausnummer Zusatz"} required={false}/>
            </div>
        </div>
        <div className={"columns"}>
            <div className={"column is-full"}>
                <CitySelect disabled={disabled} invalid={Boolean(cityHelp)} help={cityHelp} city={holder.city}
                            zip={holder.postCode} required={true}
                            onChange={v => {
                                if (!v) {
                                    holder.city = undefined
                                    holder.postCode = undefined
                                } else {
                                    holder.city = v.city
                                    holder.postCode = v.zip
                                }
                                registrationStore.holder = holder
                            }}
                            onBlur={() => registrationStore.validate()}/>
            </div>
        </div>
        <div className={"columns"}>
            <div className={"column is-fullwidth"}>
                <FieldInput
                    disabled={disabled}
                    invalid={Boolean(emailAddressHelp)}
                    help={emailAddressHelp}
                    value={holder.emailAddress}
                    onChange={v => update('emailAddress', v)}
                    onBlur={() => registrationStore.validate()}
                    label={"E-Mail"}
                    required={false}/>
            </div>
        </div>
        <div className={"columns"}>
            <div className={"column is-one-third"}>
                <FieldAutoComplete
                    disabled={disabled}
                    invalid={Boolean(courtIdHelp)}
                    help={courtIdHelp}
                    label={"Registergericht"}
                    required={true}
                    value={holder.courtId}
                    options={courtOptions}
                    onChange={v => update('courtId', v)}
                    onBlur={() => registrationStore.validate()}
                    itemRenderer={e => courtName(e as CourtEnum)}
                    search={async v => {
                        return searchCourt(v)
                    }}
                />
            </div>
            <div className={"column is-one-third"}>
                <FieldSelect
                    disabled={disabled}
                    invalid={Boolean(registerTypeHelp)}
                    help={registerTypeHelp}
                    options={registerTypeOptions}
                    value={holder.registerType}
                    onChange={v => update('registerType', v)}
                    onBlur={() => registrationStore.validate()}
                    label={"Registerart"}
                    required={true}/>
            </div>
            <div className={"column is-one-third"}>
                <FieldInput
                    disabled={disabled}
                    invalid={Boolean(registerNumberHelp)}
                    help={registerNumberHelp}
                    value={holder.registerNumber}
                    onChange={v => update('registerNumber', v)}
                    onBlur={() => registrationStore.validate()}
                    label={"Registernummer"}
                    required={true}/>
            </div>
        </div>
    </>
})

export {
    HolderLegalForm
}
